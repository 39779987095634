import Link from "next/link";
import MenuItem from "@mui/material/MenuItem"; 
// STYLED COMPONENT

import { SearchResultCard } from "../styles"; 
// ==============================================================


// ==============================================================
export default function SearchResult({
  results,
  toggleSearchBar
}) {
  return <SearchResultCard elevation={2}>
      {results.map(item => <Link href={`/products/search/${item.display_name}`} key={item.display_name}>
          <MenuItem onClick={toggleSearchBar} key={item.display_name}>{item.display_name}</MenuItem>
        </Link>)}
    </SearchResultCard>;
}