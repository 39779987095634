import { useState, useEffect, useCallback } from "react";
import axios from "../../../utils/axiosInstance";

const useSearch = () => {
  const [keyword, setKeyword] = useState('');
  const [resultList, setResultList] = useState([]);
  
  const handleSearch = useCallback((event) => {
    setKeyword(event.target.value);
  }, []);

  const searchProducts = async () => {
    try {
      const response = await axios.get("/api/search", {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
        params: { keyword: keyword, }
      });
      const productsJson = JSON.parse(response.data.data);
      setResultList(productsJson); // Ensure data exists and is valid JSON
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Debounce or delay search execution
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (keyword) {
        searchProducts();
      }
    }, 500); // 500ms delay for debounce

    return () => clearTimeout(delayDebounceFn); // Cleanup debounce
  }, [keyword]);

  return {
    handleSearch,
    resultList,
    searchProducts,
    keyword
  };
};

export default useSearch;